/* General Hero Section Styling */
.hero {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px;
  background-color: #fff8f0;
  text-align: center;
  flex-direction: column;
}

.content-wrapper {
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px; /* Increase spacing */
}

.hover-container {
  position: relative;
  text-align: center;
  transition: transform 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.hover-container:hover .top-content {
  transform: translateY(30px); /* Move greeting downward */
}

.hover-container:hover .main-content .testimonial {
  transform: translateY(-30px); /* Move testimonial upward */
}

.hover-container:hover .main-content .rating {
  transform: translateY(-30px); /* Move rating upward */
}

.hover-container:hover .image-container .decorative-image {
  opacity: 1; /* Make decorative image visible */
  transform: scale(1.05); /* Slight zoom */
}

/* Top Content */
.top-content {
  text-align: center;
  position: relative;
  transition: transform 0.3s ease;
}

.greeting {
  font-size: 1.5rem;
  color: #ff6600;
  margin-bottom: -10px; /* Add more spacing */
  transition: transform 0.3s ease;
}

h1 {
  font-size: 3rem; /* Reduce size for better spacing */
  font-weight: bold;
  line-height: 1.2;
  color: #333;
}

h1 span {
  color: #ff6600;
}

/* Main Content Layout */
.main-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 40px; /* Increase spacing */
  transition: transform 0.3s ease;
}

.testimonial {
  flex: 1;
  font-style: italic;
  color: #666;
  font-size: 1.2rem;
  text-align: left;
  transition: transform 0.3s ease;
}

.image-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 300px;
  padding: 0px; /* Add padding around the image */
  transform: translateY(-5px);
  margin-bottom: -15px;
}

.background-shape {
  position: absolute;
  bottom: 0px;
  width: 600px;
  height: 300px;
  background-color: #ffb649;
  border-radius: 300px 300px 0 0;
  z-index: 0;
  margin-bottom: 20px;
}

.profile-picture {
  width: 390px;
  height: auto;
  bottom: -30px;
  transition: transform 0.3s ease;
  z-index: 1;
  margin-bottom: 20px;
}

.decorative-image {
  position: absolute;
  bottom: 0px;
  width: 600px;
  height: auto;
  opacity: 0; /* Initially hidden */
  transition: opacity 0.3s ease, transform 0.3s ease;
}

/* Rating Section */
.rating {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: left;
  font-size: 1rem;
  color: #666;
  transition: transform 0.3s ease;
}

.stars span {
  font-size: 1.5rem;
  color: #ffcc00;
}

.stars {
  display: flex;
  gap: 5px;
}

/* Buttons */
.cta-buttons {
  display: flex;
  gap: 20px;
  margin-top: -80px; /* Move buttons up relative to their parent */
  z-index: 2; /* Ensure buttons appear above other elements */
  justify-content: center; /* Center-align the buttons */
}

.btn-primary {
  background-color: #ff6600;
  color: #fff;
  padding: 15px 30px;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  transition: transform 0.2s ease, background-color 0.2s ease;
}

.btn-primary:hover {
  background-color: #e55a00; /* Darker orange on hover */
  transform: scale(1.05); /* Slight enlargement */
}

.btn-secondary {
  background-color: #f4f4f4;
  color: #333;
  padding: 15px 30px;
  border: 1px solid #ddd;
  border-radius: 30px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  transition: transform 0.2s ease, background-color 0.2s ease;
}

.btn-secondary:hover {
  background-color: #eaeaea; /* Light grey on hover */
  transform: scale(1.05); /* Slight enlargement */
}

/* Desktop */
@media (min-width: 1024px) {
  .hero {
    padding: 60px;
  }

  h1 {
    font-size: 3.5rem;
  }

  .image-container {
    max-width: 400px;
  }
}

/* Tablet */
@media (min-width: 768px) and (max-width: 1023px) {
  .hero {
    padding: 40px;
    flex-direction: column;
  }

  h1 {
    font-size: 2.5rem;
  }

  .main-content {
    flex-direction: column;
    gap: 20px;
  }

  .testimonial, .rating {
    text-align: center;
  }

  .image-container {
    max-width: 350px;
    transform: translateY(0);
  }

  .cta-buttons {
    margin-top: 20px;
    flex-direction: column;
    gap: 10px;
  }
  .hover-container:hover .top-content {
    transform: none; /* Disable greeting hover */
  }

  .hover-container:hover .main-content .testimonial {
    transform: none; /* Disable testimonial hover */
  }

  .hover-container:hover .main-content .rating {
    transform: none; /* Disable rating hover */
  }

  .hover-container:hover .image-container .decorative-image {
    opacity: 1; /* Ensure the decorative image remains visible */
    transform: none; /* Disable zoom effect */
  } 
}

/* Mobile */
/* Mobile */
@media (max-width: 767px) {
  .hero {
    padding: 20px;
    text-align: center; /* Center-align content for mobile */
  }

  h1 {
    font-size: 2rem;
    line-height: 1.3;
    margin-bottom: 15px;
  }

  .main-content {
    flex-direction: column;
    align-items: center;
    gap: 20px;
    text-align: center; /* Align text for smaller screens */
  }

  .testimonial,
  .rating {
    font-size: 1rem;
    text-align: center;
    margin-bottom: 10px;
  }

  .image-container {
    width: 200px;
    height: auto; /* Maintain aspect ratio */
    margin: 0 auto; /* Center-align image container */
    transform: translateY(0); /* Remove hover transformation for mobile */
  }

  .background-shape {
    width: 220px;
    height: 110px; /* Adjust shape size for mobile */
    margin: 0 auto; /* Center-align background shape */
  }

  .decorative-image {
    width: 60px;
    height: auto;
    position: absolute;
    bottom: -10px; /* Adjust position for mobile */
    left: 50%;
    transform: translateX(-50%);
  }

  .profile-picture {
    width: 150px; /* Adjust profile image size */
    height: auto;
    margin: 0 auto; /* Center-align profile picture */
  }

  .cta-buttons {
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin-top: 15px; /* Add spacing above buttons */
  }

  .btn-primary,
  .btn-secondary {
    padding: 10px 20px;
    font-size: 0.9rem;
    border-radius: 25px; /* Round button edges for a cleaner look */
    width: 200px; /* Ensure buttons are consistent in width */
    text-align: center;
  }

  .btn-primary {
    background-color: #ff6600;
    color: #fff;
  }

  .btn-secondary {
    background-color: #f4f4f4;
    color: #333;
  }

  .btn-primary:hover,
  .btn-secondary:hover {
    transform: none; /* Remove hover effects for touch devices */
  }
  
  .hover-container:hover .top-content {
    transform: none; /* Disable greeting hover */
  }

  .hover-container:hover .main-content .testimonial {
    transform: none; /* Disable testimonial hover */
  }

  .hover-container:hover .main-content .rating {
    transform: none; /* Disable rating hover */
  }

  .hover-container:hover .image-container .decorative-image {
    opacity: 1; /* Ensure the decorative image remains visible */
    transform: none; /* Disable zoom effect */
  }
}