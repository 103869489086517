.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #fff8f0;
  border-top: 1px solid #ddd;
  font-size: 14px;
  color: #555;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.footer p {
  margin: 0;
}

.footer-icons {
  display: flex;
  gap: 10px;
}

.footer-icons a {
  display: inline-block;
  width: 30px;
  height: 30px;
  border: 1px solid #ddd;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #555;
  text-decoration: none;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.footer-icons a:hover {
  background-color: #555;
  color: #fff;
}

.footer-icons i {
  font-size: 18px;
}
