.certifications {
    padding: 60px 20px;
    background-color: #fff8f0;
    text-align: center;
    font-family: "Poppins", sans-serif;
  }
  
  .certifications-header {
    font-size: 1rem;
    font-weight: 400;
    color: #191919; 
    margin-bottom: 20px;
  }
  
  .certifications-header span {
    font-size: 2.5rem; 
    font-weight: 700; 
    color: #ff6600; 
    display: block; 
    margin-bottom: 10px; 
  }  
  
  .certifications-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 30px;
    justify-items: center;
  }
  
  .certification-entry {
    background-color: #fff;
    border: 2px solid #ff6600;
    border-radius: 15px;
    padding: 20px;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .certification-entry:hover {
    transform: translateY(-10px);
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.2);
  }
  
  .certification-entry img {
    width: 100px;
    height: auto;
    margin-bottom: 15px;
    border-radius: 10px;
  }
  
  .certification-entry h3 {
    font-size: 1.2rem;
    color: #4b4b4b;
    margin-bottom: 10px;
  }
  
  .validate-button {
    background-color: #ff6600;
    color: #fff;
    padding: 10px 20px;
    font-size: 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  .validate-button:hover {
    background-color: #000000;
    transform: scale(1.05);
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .certifications-header {
      font-size: 2rem;
    }
  
    .certification-entry img {
      width: 80px;
    }
  
    .validate-button {
      padding: 8px 15px;
      font-size: 0.9rem;
    }
  }
  